* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    color: white;
    font-family: "Poppins", sans-serif;
    min-height: 100vh;
  }
  header {
    font-size: 2rem;
  }
  
  header,
  form {
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  form input,
  form button {
    padding: 0.5rem;
    font-size: 2rem;
    border: none;
    background: white;
  }
  form button {
    color: #ff6f47;
    background: #f7fffe;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  form button:hover {
    background: #ff6f47;
    color: white;
  }
  .todo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .todo-list {
    min-width: 30%;
    list-style: none;
  }
  
  .todo {
    margin: 0.5rem;
    background: white;
    font-size: 1.5rem;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 1s ease;
  }
  .filter-todo {
    padding: 1rem;
  }
  .filter-todo > option{
    font-size: 29px;

  }
  .todo li {
    flex: 1;
  }
  
  .trash-btn,
  .complete-btn {
    background: #ff6f47;
    color: white;
    border: none;
    padding: 1rem;
    cursor: pointer;
    font-size: 1rem;
  }
  .complete-btn {
    background: rgb(11, 212, 162);
  }
  .todo-item {
    padding: 0rem 0.5rem;
  }
  
  .fa-trash,
  .fa-check {
    pointer-events: none;
  }
  
  .fall {
    transform: translateY(10rem) rotateZ(20deg);
    opacity: 0;
  }
  
  .completed {
    text-decoration: line-through;
    opacity: 0.5;
  }
  
  /*CUSTOM SELECTOR */
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background-image: none;
  }
  
  /* Custom Select */
  .select {
    margin: 1rem;
    position: relative;
    overflow: hidden;
  }
  select {
    color: #ff6f47;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    width: 12rem;
  }
  /* Arrow */
  .select::after {
    content: "\25BC";
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    background: #ff6f47;
    cursor: pointer;
    pointer-events: none;
  }
  /* Transition */
  /*
  .select:hover::after {
    transform: scale(1.5);
  }
  */